import React from "react"
import { Link } from "gatsby"
// import Logo from "../../components/Logo"
const LeftImage = ({ image, content }) => {
    return (
        <section className="section-thanks pt-5 text-center pb-5">
            <div className="container">

                <h2>Gửi Liên Hệ Thành Công</h2>
                <p>Cảm ơn Quý khách đã gửi liên hệ</p>
                <p><b>Alphaland</b> sẽ sớm liên hệ lại cho Quý khách trong thời gian sớm nhất</p>
                <Link to="/">
                    Quay về Trang chủ
                </Link>
            </div>
        </section>
    )
}

export default LeftImage
