import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const WhyChoose = ({ backgroundColor, background, item }) => {
  return (
    <section className="why-choose pt-5 pb-5 mb-4 mt-4">
      <div className="container">
        <div className="row">
          {item &&
            item.map((prop, index) => (
              <div className="col-md-4 col-xs-12 text-center" key={index}>
                <div className="icon">
                  <GatsbyImage
                    image={getImage(prop.icon.localFile)}
                    alt={prop.icon.altText ?? 'khong co hinh'}
                  />
                </div>
                <h3>{prop.title}</h3>
                <p>{prop.content}</p>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default WhyChoose
