import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import CommonLayout from "../components/Page"
import Seo from "../components/Seo"

const PageTemplate = ({
  pageContext,
  data: {
    wpPage: { pageBuilder, title, featuredImage, id },
  },
}) => {
  return (
    <Layout isHomePage>
      <Seo
        title={title}
        description={
          title + " Sàn bất động sản uy tín, chuyên nghiệp Alphaland"
        }
        keywords={
          title +
          " Sàn bất động sản, nhà đất tp hcm, dự án bất động sản, chung cư tphcm, nhà đất Bình Dương, Nhà đất Đồng Nai"
        }
        seoImage={
          "https://www.alphaholdings.vn" +
          (featuredImage
            ? featuredImage.image.childImageSharp.gatsbyImageData.images
                .fallback.src
            : "/ogimage-default.jpg")
        }
      />
      {(() => {
        if (id === "cG9zdDoxNQ==") {
          return <></>
        } else {
          return (
            <div className="big-title mb-5">
              <div className="container text-center">
                <h1> {title} </h1>
              </div>
            </div>
          )
        }
      })()}

      {pageBuilder.layouts.map((layout, index) => {
        const pcontext = pageContext
        if (
          layout.fieldGroupName === "page_Pagebuilder_Layouts_FeaturedBuyHouse"
        ) {
          pcontext.id = layout.category[0].id
        }
        //return <div key={index}>xin chao {layout?.fieldGroupName}</div>
        return <CommonLayout key={index} {...layout} pageContext={pcontext} />
      })}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      id
      pageBuilder {
        fieldGroupName
        layouts {
          ... on WpPage_Pagebuilder_Layouts_Slider {
            fieldGroupName
            slider {
              fieldGroupName
              image {
                altText
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 2.5
                      quality: 80
                      layout: FULL_WIDTH
                      placeholder: TRACED_SVG
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_About {
            content
            title
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000
                    height: 600
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_WhyChoose {
            backgroundColor
            fieldGroupName
            item {
              content
              fieldGroupName
              title
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 75
                      width: 75
                      placeholder: TRACED_SVG
                    )
                  }
                }
              }
            }
            background {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    placeholder: TRACED_SVG
                    width: 800
                  )
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_FeaturedCategory {
            fieldGroupName
            title
            category {
              id
              termTaxonomyId
              posts {
                nodes {
                  id
                  title
                  excerpt
                  uri
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 80
                            aspectRatio: 1.5
                            width: 800
                            placeholder: TRACED_SVG
                          )
                        }
                      }
                    }
                  }
                  CustomPost {
                    acreage
                    address
                    bedroom
                    fieldGroupName
                    price
                    wc
                  }
                  date(difference: "minutes")
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_FeaturedSellHouse {
            fieldGroupName
            title
            category {
              id
              termTaxonomyId
              posts {
                nodes {
                  id
                  title
                  excerpt
                  uri
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 80
                            aspectRatio: 1.5
                            width: 800
                            placeholder: TRACED_SVG
                          )
                        }
                      }
                    }
                  }
                  CustomPost {
                    acreage
                    address
                    bedroom
                    fieldGroupName
                    price
                    wc
                  }
                  date(difference: "minutes")
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_FeaturedBuyHouse {
            fieldGroupName
            title
            category {
              id
              termTaxonomyId
              posts {
                nodes {
                  id
                  title
                  excerpt
                  uri
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 80
                            aspectRatio: 1.5
                            width: 800
                            placeholder: TRACED_SVG
                          )
                        }
                      }
                    }
                  }
                  CustomPost {
                    acreage
                    address
                    bedroom
                    fieldGroupName
                    price
                    wc
                  }
                  date(difference: "minutes")
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_FeaturedNew {
            fieldGroupName
            title
            category {
              id
              termTaxonomyId
              posts {
                nodes {
                  id
                  title
                  excerpt
                  uri
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 80
                            aspectRatio: 1.5
                            width: 800
                            placeholder: TRACED_SVG
                          )
                        }
                      }
                    }
                  }
                  CustomPost {
                    acreage
                    address
                    bedroom
                    fieldGroupName
                    price
                    wc
                  }
                  date(difference: "minutes")
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_Investor {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 800
                    placeholder: TRACED_SVG
                    width: 1200
                  )
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_Content {
            content
            fieldGroupName
          }
          ... on WpPage_Pagebuilder_Layouts_LeftImage {
            content
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    aspectRatio: 1.5
                    placeholder: TRACED_SVG
                  )
                }
              }
              altText
            }
          }
          ... on WpPage_Pagebuilder_Layouts_RightImage {
            content
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    layout: CONSTRAINED
                    placeholder: TRACED_SVG
                    width: 800
                  )
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_ContactForm {
            fieldGroupName
            form
            title
            subTitle
            background {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.5
                    width: 800
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
