import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"

const FeaturedNew = ({ title, category }) => {
    let posts = []
    category.forEach(cat => {
      posts.push(...cat.posts.nodes)
    })
    posts = posts
      .sort((a, b) => parseInt(a.date) - parseInt(b.date))
      .slice(0, posts.length > 6 ? 6 : posts.length)
  return (
    <section className="featured-category pt-4">
      <div className="container">
        <div className="title text-center w-100">
          <h2>{title}</h2>
        </div>
        <div className="list-post w-100">
          <div className="row">
            {posts &&
              posts.map((prop, index) => (
                <div className="col-md-4 col-xs-12" key={index}>
                  <article className="item">
                    <div className="featured-image">
                      <Link to={`${prop.uri}`}>
                        <div className="ov1"></div>
                        <div className="ov2"></div>
                        <div className="ov3"></div>
                        <div className="ov4"></div>
                        <GatsbyImage
                          image={getImage(prop.featuredImage.node.localFile)}
                          alt={prop.featuredImage.node.altText ?? 'khong co hinh'}
                        />
                      </Link>
                    </div>
                    <div className="title">
                      <Link to={`${prop.uri}`} title={prop.title}>
                        <h3>{prop.title}</h3>
                      </Link>
                    </div>
                    <div className="excerpt">
                      <div>
                        {parse(
                          prop.excerpt.replace(/<p class="link-more.*/, "")
                        )}
                      </div>
                    </div>
                  </article>
                </div>
              ))}
          </div>
          <div className="link-category text-center pb-5">
            <Link to={`tin-tuc`}>Xem thêm</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedNew
