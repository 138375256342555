import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"
const About = ({ title, image, content }) => {
  // console.log(title);
  return (
    <section className="about pt-4 pb-4">
      <div className="container">
        <div className="title text-center">
          <h2>{title}</h2>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <GatsbyImage image={getImage(image.localFile)} alt={image.altText ?? 'khong co hinh'} />
          </div>
          <div className="col-md-6 col-xs-12">
            <div>{parse(content)}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
