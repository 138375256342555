import React from "react"
import parse from "html-react-parser"
import ContactForm from "../Form/ContactForm"
const RightImage = ({ image, content }) => {
    return (
        <section className="section-col pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <div className="content">
                            <div>{parse(content)}</div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <div className="image">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RightImage
