import React from "react"
// import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Investor = ({ image }) => {
  // var settings = {
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 6,
  //     slidesToScroll: 1
  // };
  return (
    <section className="investor">
      <div className="container">
        {/* <div className="title text-center w-100">
          <h2>{title}</h2>
        </div>
        <div className="c-row">
          <Slider {...settings}>
            {gallery &&
              gallery.map((prop, index) => (
                <div key={index}>
                  <FluidImage image={prop.image} />
                </div>
              ))}
          </Slider>
        </div> */}
        <GatsbyImage image={getImage(image.localFile)} alt={image.altText ?? 'khong co hinh'}/>
      </div>
    </section>
  )
}

export default Investor
