import React from "react"
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Slide = ({ slider, image }) => {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        fade: true,
        arrows: false,
        slidesToScroll: 1
    };
    return (
        <section className="slider pb-4">
            <Slider {...settings}>
                {
                    slider &&
                    slider.map((prop, index) => (
                        <div key={index}>
                            <GatsbyImage
                                    image={getImage(
                                      prop.image.localFile
                                    )}
                                    alt={prop.image.altText ?? 'khong co hinh'}
                                  />
                        </div>
                    ))
                }
            </Slider>
        </section>
    )
}

export default Slide
