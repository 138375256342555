import React from "react"
import About from "./About"
import Content from "./Content"
import FeaturedBuyHouse from "./FeaturedBuyHouse"
import FeaturedCategory from "./FeaturedCategory"
import FeaturedNew from "./FeaturedNew"
import FeaturedSellHouse from "./FeaturedSellHouse"
import Investor from "./Investor"
import LeftImage from "./LeftImage"
import RightImage from "./RightImage"
import Slider from "./Slider"
import WhyChoose from "./WhyChoose"
const CommonLayout = (props) => {

  const Default = () => <div>In AllLayouts the mapping of this component is missing: {props.fieldGroupName}</div>

  const components = {
    page_Pagebuilder_Layouts_Slider: Slider,
    page_Pagebuilder_Layouts_About: About,
    page_Pagebuilder_Layouts_WhyChoose: WhyChoose,
    page_Pagebuilder_Layouts_FeaturedCategory: FeaturedCategory,
    page_Pagebuilder_Layouts_Investor: Investor,
    page_Pagebuilder_Layouts_FeaturedSellHouse: FeaturedSellHouse,
    page_Pagebuilder_Layouts_FeaturedBuyHouse: FeaturedBuyHouse,
    page_Pagebuilder_Layouts_FeaturedNew: FeaturedNew,
    page_Pagebuilder_Layouts_Content: Content,
    page_Pagebuilder_Layouts_RightImage:RightImage,
    page_Pagebuilder_Layouts_LeftImage:LeftImage,
    page_default: Default
  }


  const ComponentName = components[props.fieldGroupName] ? components[props.fieldGroupName] : components['page_default']

  return (
    <ComponentName {...props} />
  )
}

export default CommonLayout
