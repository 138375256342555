import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import area from "../../images/area.svg"
import bath from "../../images/bath.svg"
import bed from "../../images/bed.svg"

const FeaturedSellHouse = ({ title, category }) => {
  let posts = []
  category.forEach(cat => {
    posts.push(...cat.posts.nodes)
  })
  posts = posts
    .sort((a, b) => parseInt(a.date) - parseInt(b.date))
    .slice(0, posts.length > 6 ? 6 : posts.length)
  return (
    <section className="featured-category pt-4">
      <div className="container">
        <div className="title text-center w-100">
          <h2>{title}</h2>
        </div>
        <div className="list-post w-100">
          <div className="row">
            {posts &&
              posts.map((prop, index) => (
                <div className="col-md-4 col-xs-12" key={index}>
                  <article className="item">
                    <div className="featured-image">
                      <Link to={`${prop.uri}`}>
                        <div className="ov1"></div>
                        <div className="ov2"></div>
                        <div className="ov3"></div>
                        <div className="ov4"></div>
                        <GatsbyImage
                          image={getImage(prop.featuredImage.node.localFile)}
                          alt={prop.featuredImage.node.altText ?? 'khong co hinh'}
                        />
                      </Link>
                    </div>
                    <div className="title">
                      <Link to={`${prop.uri}`} title={prop.title}>
                        <h3>{prop.title}</h3>
                      </Link>
                    </div>
                    <div className="excerpt">
                      <div className="address">
                        <span>
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                          {prop.CustomPost.address}
                        </span>
                      </div>
                      <div className="price">
                        <span>Giá: ~ {prop.CustomPost.price}</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <span>
                            <img src={bed} alt="bed" />
                            {prop.CustomPost.bedroom}
                          </span>
                        </div>
                        <div>
                          <span>
                            <img src={bath} alt="bath" />
                            {prop.CustomPost.wc}
                          </span>
                        </div>
                        <div>
                          <span>
                            <img src={area} alt="area" />
                            {prop.CustomPost.acreage}
                          </span>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
          </div>
          <div className="link-category text-center pb-5">
            <Link to={`mua-nha`}>Xem thêm</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedSellHouse
