import parse from "html-react-parser"
import React from "react"
const Content = ({ content }) => {
  return (
    <section className="content-page pt-4 pb-4">
      <div className="container">
        <div>{parse(content)}</div>
      </div>
    </section>
  )
}

export default Content
